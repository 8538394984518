import React, { useEffect, useState } from "react";
import { TextField, Paper, useTheme, Grid } from "@material-ui/core";
import styled from "@emotion/styled";
import { useForm, useFormState } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { rhythm, AllowInputDecimal, ConvertThaiDate, scale } from "src/utils";
import { BigNumber } from "bignumber.js";

const Title = styled.p`
  border-bottom: 0.5px solid;
  margin-bottom: ${rhythm(1)};
`;

const schema = yup.object({
  asset: yup.string(),
  invest: yup
    .string()
    .matches(/^[0-9\.]+$/g, "เฉพาะตัวเลขเท่านั้น")
    .required("กรุณากรอกข้อมูล"),
  takeProfit: yup
    .string()
    .matches(/^[0-9\.]+$/g, "เฉพาะตัวเลขเท่านั้น")
    .required("กรุณากรอกข้อมูล"),
  stopLoss: yup
    .string()
    .matches(/^[0-9\.]+$/g, "เฉพาะตัวเลขเท่านั้น")
    .required("กรุณากรอกข้อมูล"),
  buy: yup
    .string()
    .matches(/^[0-9\.]+$/g, "เฉพาะตัวเลขเท่านั้น")
    .required("กรุณากรอกข้อมูล"),
});

interface Schema {
  asset?: string;
  invest?: string;
  takeProfit: string;
  stopLoss: string;
  buy: string;
}

/**
    ตัวอย่าง สมมติเราอยากซื้อหุ้น A ที่ราคา 30 บาท โดยราคาเป้าหมายที่น่าจะไปถึงและจุดตัดขาดทุนเมื่อวิเคราะห์จากกราฟอยู่ที่ 40 และ 25 บาทตามลำดับ

    RRR = (ราคาเป้าหมาย – ราคาที่จะซื้อ) / (ราคาที่จะซื้อ – ราคาที่จะตัดขาดทุน)
        = (40 – 30) / (30 – 25)
        = 10 / 5 = 2
 */

function RRRCalculator() {
  const theme = useTheme();
  const { control, watch, register } = useForm<Schema>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { errors } = useFormState({ control });

  const [attemptDate, setAttemptDate] = useState(new Date());

  useEffect(() => {
    const sub = watch((v) => {
      setAttemptDate(new Date());
    });

    return () => sub.unsubscribe();
  }, [setAttemptDate, watch]);

  return (
    <Paper variant="outlined" style={{ padding: rhythm(1) }}>
      <form autoComplete="off">
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Title>ข้อมูล</Title>
            <TextField
              inputProps={register("asset")}
              inputMode="text"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: "" }}
              placeholder="พิมพ์ชื่อสินทรัพย์"
              label="ชื่อสินทรัพย์"
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
            <TextField
              inputProps={register("invest")}
              inputMode="numeric"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: "$" }}
              placeholder="พิมพ์จำนวนเงิน"
              label="จำนวนเงิน"
              onKeyPress={AllowInputDecimal}
              error={!!errors?.invest && !!errors?.invest?.message}
              helperText={
                errors?.invest ? (
                  <span style={{ color: theme?.palette?.error?.main }}>
                    {errors?.invest && errors?.invest?.message}
                  </span>
                ) : (
                  <span>กรอกเฉพาะตัวเลขเท่านั้น</span>
                )
              }
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
            <TextField
              inputProps={register("takeProfit")}
              inputMode="numeric"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: "" }}
              placeholder="พิมพ์ราคาเป้าหมาย"
              label="ราคาเป้าหมาย"
              onKeyPress={AllowInputDecimal}
              error={!!errors?.takeProfit && !!errors?.takeProfit?.message}
              helperText={
                errors?.takeProfit ? (
                  <span style={{ color: theme?.palette?.error?.main }}>
                    {errors?.takeProfit && errors?.takeProfit?.message}
                  </span>
                ) : (
                  <span>กรอกเฉพาะตัวเลขเท่านั้น</span>
                )
              }
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
            <TextField
              inputProps={register("stopLoss")}
              inputMode="numeric"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: "" }}
              placeholder="พิมพ์ราคาที่จะตัดขาดทุน"
              label="ราคาที่จะตัดขาดทุน"
              onKeyPress={AllowInputDecimal}
              error={!!errors?.stopLoss && !!errors?.stopLoss?.message}
              helperText={
                errors?.stopLoss ? (
                  <span style={{ color: theme?.palette?.error?.main }}>
                    {errors?.stopLoss && errors?.stopLoss?.message}
                  </span>
                ) : (
                  <span>กรอกเฉพาะตัวเลขเท่านั้น</span>
                )
              }
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
            <TextField
              inputProps={register("buy")}
              inputMode="numeric"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: "" }}
              placeholder="พิมพ์ราคาที่จะซื้อ"
              label="ราคาที่จะซื้อ"
              onKeyPress={AllowInputDecimal}
              error={!!errors?.buy && !!errors?.buy?.message}
              helperText={
                errors?.buy ? (
                  <span style={{ color: theme?.palette?.error?.main }}>
                    {errors?.buy && errors?.buy?.message}
                  </span>
                ) : (
                  <span>กรอกเฉพาะตัวเลขเท่านั้น</span>
                )
              }
              fullWidth
              style={{ marginBottom: rhythm(1) }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h3
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "0.5px solid",
                marginBottom: rhythm(1),
                fontWeight: 400,
                ...scale(0 / 5),
              }}
            >
              <span>ผลลัพท์</span> <span>{ConvertThaiDate(attemptDate)}</span>
            </h3>
            <div
              style={{
                textAlign: "left",
              }}
            >
              <RRR
                buy={watch("buy")}
                stopLoss={watch("stopLoss")}
                takeProfit={watch("takeProfit")}
                asset={watch("asset")}
                invest={watch("invest")}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default RRRCalculator;

interface RRRProps {
  takeProfit: string;
  stopLoss: string;
  buy: string;
  asset?: string;
  invest?: string;
}

function RRR(props: RRRProps) {
  const {
    takeProfit = "",
    buy = "",
    stopLoss = "",
    asset = "-",
    invest = "-",
  } = props;
  const profit = props?.invest
    ? `${new BigNumber(
        new BigNumber(+props?.invest).dividedBy(+buy).times(+takeProfit)
      )
        .minus(+props?.invest)
        .toFixed(2)} (${new BigNumber(+props?.invest)
        .dividedBy(+buy)
        .times(+takeProfit)
        .toFixed(2)})`
    : "-";
  const loss = props?.invest
    ? `${new BigNumber(
        new BigNumber(+props?.invest).dividedBy(+buy).times(+stopLoss)
      )
        .minus(+props?.invest)
        .toFixed(2)} (${new BigNumber(+props?.invest)
        .dividedBy(+buy)
        .times(+stopLoss)
        .toFixed(2)})`
    : "-";

  return (
    <>
      <h3 style={{ fontWeight: 400 }}>ชื่อสินทรัพย์: {asset}</h3>
      <p>{`ลงทุน ($) = ${invest}`}</p>
      <p>
        {`RRR = (ราคาเป้าหมาย – ราคาที่จะซื้อ) / (ราคาที่จะซื้อ – ราคาที่จะตัดขาดทุน)`}
      </p>
      <p>{`RRR = (${takeProfit} – ${buy}) / (${buy} – ${stopLoss})`}</p>
      <p>
        {`RRR = ${new BigNumber(
          new BigNumber(+props?.takeProfit).minus(+props?.buy)
        )
          .dividedBy(new BigNumber(+props?.buy).minus(+props?.stopLoss))
          .toFixed(3)}`}
      </p>
      <hr />
      <p>{`กำไร = ${profit}`}</p>
      <p>{`ขาดทุน = ${loss}`}</p>
    </>
  );
}
