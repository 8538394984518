import React from "react";
import Helmet from "react-helmet";
import { Container } from "@material-ui/core";
import { NavBar } from "../components/NavBar";
import RRRCalculator from "../components/RRRCalculator";

function RiskToRewardRatioCalculatorPage() {
  return (
    <>
      <Helmet>
        <title>
          โปรแกรมคำนวณ Risk to reward ratio ออนไลน์ — Calculate Anything
        </title>
        <meta
          name="description"
          content="ตัวช่วยคำนวณ Risk to reward ratio ออนไลน์ ประกอบการตัดสินใจ"
        />
      </Helmet>
      <Container maxWidth="lg">
        <NavBar />
        <h1>โปรแกรมคำนวณ Reward to Risk Ratio ออนไลน์ (RRR)</h1>
        <section>
          {typeof window !== `undefined` ? <RRRCalculator /> : <>Loading..</>}
        </section>
      </Container>
    </>
  );
}

export default RiskToRewardRatioCalculatorPage;
